// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// Partials
@import "custom/partials/_highlight.scss";

// Pages
@import "custom/pages/content";

html, 
body {
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #111;
}

h1, h2, h3, h4 {
  font-weight: 500;
}

h5, h6 {
  font-weight: 500;
}

a {
  font-weight: 500;
  transition: all .1s ease-in-out;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}



.section {
  border: 1px dashed #fff;
}
