@tailwind base;
@tailwind components;
@tailwind utilities;
.highlight {
  color: #bbb;
}
.highlight.highlight-light {
  color: #888;
}

.highlight-underline {
  position: relative;
  z-index: 0;
}
.highlight-underline::after {
  content: "";
  position: absolute;
  bottom: -0.35rem;
  left: -23%;
  right: 0;
  height: 40%;
  width: 125%;
  z-index: -1;
  background-image: url("/assets/img/highlight.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.highlight-underline.highlight-underline-light::after {
  background-image: url("/assets/img/highlight-light.svg");
}

.text-5xl .highlight-underline::after {
  bottom: -0.2rem !important;
}

.text-4xl .highlight-underline::after {
  bottom: -0.2rem !important;
}

.text-3xl .highlight-underline::after {
  bottom: -0.2rem !important;
}

.content-page a {
  font-weight: 700;
  color: #666;
  text-decoration: underline;
}
.content-page h1, .content-page h2, .content-page h3, .content-page h4, .content-page h5, .content-page h6 {
  font-weight: 600;
  font-family: "Inter", sans-serif;
  margin-bottom: 1rem;
}
.content-page h1 {
  margin-top: 3rem;
  font-size: 3rem;
}
.content-page h2 {
  margin-top: 2.5rem;
  font-size: 2rem;
}
.content-page h3 {
  margin-top: 2rem;
  font-size: 1.5rem;
}
.content-page h4 {
  margin-top: 2rem;
  font-size: 1.3rem;
}
.content-page h5 {
  margin-top: 1rem;
  font-size: 1.2rem;
}
.content-page h6 {
  margin-top: 1rem;
  font-size: 1.2rem;
}
.content-page p, .content-page ol, .content-page ul, .content-page blockquote, .content-page pre, .content-page table, .content-page figure, .content-page div.callout {
  margin: 1rem 0;
  font-size: 1.2rem;
}
.content-page ol, .content-page ul {
  margin-left: 1.4rem;
  font-size: 1rem;
}
.content-page ol li, .content-page ul li {
  margin-bottom: 1rem;
}
.content-page ol {
  list-style-type: decimal;
}
.content-page ul {
  list-style-type: disc;
}
.content-page blockquote {
  border-left: 4px solid #ccc;
  padding: 0.2rem 0 0.2rem 1rem;
  font-style: italic;
  font-size: 1rem;
}
.content-page pre {
  font-size: 1rem;
  padding: 0.6rem 1rem;
  background: #111;
}
.content-page pre code {
  padding: 0;
}
.content-page code {
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  background: #111;
  color: #fff;
  padding: 0.01rem 0.2rem 0.04rem 0.2rem;
}
.content-page figure figcaption {
  font-size: 0.8rem;
  font-style: italic;
  color: #666;
}
.content-page div.callout {
  padding: 0.6rem 1rem;
  display: flex;
  align-items: center;
}
.content-page div.callout div.callout-emoji {
  display: relative;
  float: left;
}
.content-page div.callout p {
  margin: 0.5rem 0.5rem 0.5rem 1rem;
  font-size: 1rem;
}
.content-page img {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

html,
body {
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #111;
}

h1, h2, h3, h4 {
  font-weight: 500;
}

h5, h6 {
  font-weight: 500;
}

a {
  font-weight: 500;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.section {
  border: 1px dashed #fff;
}