.highlight {
  color: #bbb;

  &.highlight-light {
    color: #888;
  }
}

.highlight-underline {
  position: relative;
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    bottom: -0.35rem;
    left: -23%;
    right: 0;
    height: 40%;
    width: 125%;
    z-index: -1;
    background-image: url("/assets/img/highlight.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.highlight-underline-light {
    &::after {
      background-image: url("/assets/img/highlight-light.svg");
    }
  }
}

.text-5xl {
  .highlight-underline {
    &::after {
      bottom: -0.2rem !important;
    }
  }
}

.text-4xl {
  .highlight-underline {
    &::after {
      bottom: -0.2rem !important;
    }
  }
}

.text-3xl {
  .highlight-underline {
    &::after {
      bottom: -0.2rem !important;
    }
  }
}
