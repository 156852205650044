.content-page {
    a {
        font-weight: 700;
        color: #666;
        text-decoration: underline;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 600;
        font-family: 'Inter', sans-serif;
        margin-bottom: 1rem;
    
    }

    h1 {
        margin-top: 3rem;
        font-size: 3rem;
    }

    h2 {
        margin-top: 2.5rem;
        font-size: 2rem;
    }

    h3 {
        margin-top: 2rem;
        font-size: 1.5rem;
    }

    h4 {
        margin-top: 2rem;
        font-size: 1.3rem;
    }

    h5 {
        margin-top: 1rem;
        font-size: 1.2rem;
    }

    h6 {
        margin-top: 1rem;
        font-size: 1.2rem;
    }

    p, ol, ul, blockquote, pre, table, figure, div.callout {
        margin: 1rem 0;
        font-size: 1.2rem;
    }

    ol, ul {
        margin-left: 1.4rem;
        font-size: 1rem;

        li {
            margin-bottom: 1rem;
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;
    }

    blockquote {
        border-left: 4px solid #ccc;
        padding: .2rem 0 .2rem 1rem;
        font-style: italic;
        font-size: 1rem;
    }

    pre {
        font-size: 1rem;
        padding: 0.6rem 1rem;
        background: #111;

        code {
            padding: 0;
        }
    }

    code {
        font-family: 'Source Code Pro', monospace;
        font-size: 1rem;
        background: #111;
        color: #fff;
        padding: .01rem .2rem .04rem .2rem;
    }

    figure {
        figcaption {
            font-size: .8rem;
            font-style: italic;
            color: #666;
        }
    }

    div.callout {
        padding: 0.6rem 1rem;
        display: flex;
        align-items: center;

        div.callout-emoji {
            display: relative;
            float: left;
        }

        p {
            margin: .5rem .5rem .5rem 1rem;
            font-size: 1rem;
        }
    }

    img {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

}